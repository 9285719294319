import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Box, Row, Typography} from "../../UI";
import {
  Button, ButtonHorizontal, CardMenu, DepositItems, Input, LineInfo, PageHeader, PageLoader,
} from "../../components";
import {useSnackbar, useTranslate, useWallet} from "../../contexts";
import {
  CHAINS, SUCCESS, VARIANT_PAY_BEP20, VARIANT_PAY_RUB, VARIANT_PAY_USD,
} from "../../config";
import styled from "styled-components";
import {IFrameLayout, INTERVAL_FETCH_MS} from "../IFrame/IFrameLayout";
import {BUY_USDT_KYC_ROUTE, MDAO_MAKER_STAKING_ROUTE, WALLET_ROUTE} from "../../routes/routes";
import {SuccessPage} from "./ProcessingPages/SuccessPage";
import {ErrorPage} from "./ProcessingPages/ErrorPage";
import {useDispatch, useSelector} from "react-redux";
import {Spinner} from "../../components/Spinner/Spinner";
import {TFACheckContainer} from "../../containers/TFACheckContainer";
import {updateStatus} from "../../api";
import {ProcessingPage} from "./ProcessingPages/ProcessingPage";
import {useGetTokenBalances} from "../../hooks/useGetTokenBalances";
import {useWertWidget} from "@wert-io/module-react-component";
import {useGetRouter} from "../../hooks";

const PAYMENT_METHOD = {
  NONE: '',
  VISA: 'visa',
  SBP: 'sbp'
}
const Wrapper = styled(Box)`
  min-height: calc(100vh - 50px);
  justify-content: space-between;
`;

export const BuyUSDTPage = () => {
  const {t} = useTranslate();
  const dispatch = useDispatch();
  const cardsDispatch = dispatch.cards;
  const walletDispatch = dispatch.wallet;
  const {showError, showErrorCode} = useSnackbar();
  const {user, chains, currentChain} = useSelector(({wallet}) => wallet);
  const {buyUsdtRequirements} = useSelector(({cards}) => cards);
  const {handleRoute} = useGetRouter();
  const [amount, setAmount] = useState("");
  const [finalAmount, setFinalAmount] = useState("");
  const [rate, setRate] = useState(0);
  const [show, setShow] = useState(false);
  const [focusForm, setFocusForm] = useState(false);
  const [recalculate, setRecalculate] = useState(false);
  const [showProcessing, setShowProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPrice, setLoadingPrice] = useState(false);
  const [refreshStatus, setRefreshStatus] = useState(false);
  const [paymentLink, setPaymentLink] = useState(null);
  const [operationId, setOperationId] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHOD.NONE);
  const [wertStatus, setWertStatus] = useState(undefined);
  const [wertClosed, setWertClosed] = useState(false);
  const [wertLoading, setWertLoading] = useState(false);

  const tokenId = useMemo(() => buyUsdtRequirements?.[VARIANT_PAY_BEP20.idTokenKey], [buyUsdtRequirements]);

  const tokenIds = useMemo(() => [tokenId], [tokenId]);
  const tokens = user?.tokens;
  const isBsc = currentChain?.chain === CHAINS.BSC;
  const wallet = isBsc ? user?.wallet : undefined;

  const {tokenBalance} = useGetTokenBalances({
    chains, chain: VARIANT_PAY_BEP20.chain, userData: user, balanceCurrentTokenId: tokenId, tokens, tokenIds,
  });

  const wertFeatureAvailable = useMemo(() => {
    // const availableUserIds = [539545925, 119911858, 24785505, 271802707, 150191933, 62003812, 61133112, 2777891];
    // return availableUserIds.includes(user?.userId);
    return true;
  }, [user]);


  useEffect(() => {
    const chain = chains.find((chain) => chain.chain === CHAINS.BSC);

    if (chain && chain.chain === CHAINS.BSC) {
      walletDispatch.setCurrentChain({
        ...chain, id: chain.chain, title: chain.title,
      });
    }
  }, [walletDispatch, chains]);

  const TFA = user?.tfaStatus;

  const wertOptions = useMemo(() => {
    const {commodity, network, origin, partner_id, commodities} = Boolean(process.env.REACT_APP_WERT_PARTNER_ID) ? {
      origin: "https://widget.wert.io",
      partner_id: process.env.REACT_APP_WERT_PARTNER_ID,
      commodity: 'USDT',
      commodities: JSON.stringify([
        {
          commodity: 'USDT',
          network: CHAINS.BSC.toLowerCase(),
        },
      ]),
    } : {
      partner_id: 'default',
      origin: 'https://sandbox.wert.io',
      commodity: 'BNB',
      commodities: JSON.stringify([
        {
          commodity: "BNB",
          network: CHAINS.BSC.toLowerCase(),
        },
      ]),
    }
    // console.log({
    //   partner_id, origin, commodity, address: wallet, network, theme: 'dark', currency_amount: 10, commodities
    // })
    return {
      partner_id,
      origin,
      commodity,
      address: wallet,
      network: CHAINS.BSC.toLowerCase(),
      theme: 'dark',
      currency_amount: amount,
      commodities,
    }
  }, [amount, wallet]);

  const [reactiveOptions] = useState({

    listeners: {
      close: () => {
        setWertClosed(true);
        setWertLoading(false);
      },
      'payment-status': (status) => setWertStatus(status),
      loaded: () => setWertLoading(false)
    },
  });

  useEffect(() => {
    if (wertStatus?.status === 'success' && wertClosed) {
      handleRoute(WALLET_ROUTE)
    }
  }, [wertStatus, wertClosed]);

  const {open} = useWertWidget(reactiveOptions);

  const {minBuyAmount, maxBuyAmount} = buyUsdtRequirements || {};
  // TODO
  const amountNotValid = amount < minBuyAmount || amount > maxBuyAmount;

  const handleChangeDepositAmount = useCallback((value) => {
    setAmount(value);
  }, []);

  const depositValues = [50, 75, 100, 200, 300, 400, 500, 1000];
  const activeItem = useMemo(() => {
    return (depositValues.find((v) => v?.toString() === amount.toString()) && amount);
  }, [amount]);

  const validateAmount = (value) => {
    if (value && (value < minBuyAmount || value > maxBuyAmount) && !focusForm) {
      showError(t("checkAmountCorrect", {
        min: minBuyAmount, max: maxBuyAmount,
      }));
      return false;
    }
    return true;
  };

  const handleUpdateStatus = useCallback(() => {
    return updateStatus(cardsDispatch.getBuyUsdtOperationStatus, {operationId}, {usdtBalance: tokenBalance}, null, "data.result");
  }, [cardsDispatch, operationId, tokenBalance]);
  console.log('wlt: ', Boolean(wallet));
  const handleBuyUsdt = (code) => {
    if (!amount) {
      showError(t("amountMustBeFilled"));
      return;
    }

    const valueIsCorrect = validateAmount(amount);

    if (!valueIsCorrect) return;

    if (paymentMethod === PAYMENT_METHOD.VISA && wertFeatureAvailable) {
      setWertClosed(false);
      setWertLoading(true);
      setWertStatus(undefined);
      // if (currentChain.chain !== CHAINS.BSC){
      //   return;
      // }
      return open({options: wertOptions})
    }

    setLoading(true);

    return cardsDispatch
        .buyUsdtMethod({amount, code})
        .then((res) => {
          if (res?.data.status === SUCCESS) {
            if (res?.data.result.paymentUrl) {
              setRefreshStatus(true);
              setPaymentLink(res?.data.result.paymentUrl);
            } else {
              setShowProcessing(true);
            }

            setOperationId(res?.data.result.operationId);
          }

          return res;
        })
        .catch((e) => {
          showErrorCode(e);
        })
        .finally(() => setLoading(false));
  };

  const handleCallIFrameCallback = useCallback(() => {
    setRefreshStatus(false);
    setPaymentLink(null);
  }, []);

  const handlePayWithTFA = () => {
    if (!amount) {
      showError(t("amountMustBeFilled"));
      return;
    }

    const valueIsCorrect = validateAmount(amount);

    if (!valueIsCorrect) return;

    setShow(true);

    return handleBuyUsdt;
  };

  useEffect(() => {
    cardsDispatch.getBuyUsdtRequirements();
  }, [cardsDispatch]);

  useEffect(() => {
    const valueIsCorrect = validateAmount(amount);

    if (amount && !amountNotValid && valueIsCorrect) {
      if (paymentMethod === PAYMENT_METHOD.VISA) {
        setFinalAmount(amount);
        setRate(-1);
      } else {
        setLoadingPrice(true);
        try {
          cardsDispatch
              .getBuyUsdtPrice({amount: Number(amount)})
              .then((res) => {
                setFinalAmount(res?.data.result.sum);
                setRate(res?.data.result.rate);
              })
              .finally(() => setLoadingPrice(false));
        } catch (e) {
          showError(e.message);
        }
      }
    }
  }, [cardsDispatch, focusForm, amount, amountNotValid, paymentMethod]);

  const submitMethod = TFA ? handlePayWithTFA : handleBuyUsdt;

  const disableButton = !amount || amountNotValid || !finalAmount || !rate || loadingPrice;

  const currency = paymentMethod === PAYMENT_METHOD.VISA ? VARIANT_PAY_USD : VARIANT_PAY_RUB;

  const handleFinishedFetch = () => {
    return cardsDispatch.getBuyUsdtOperation({operationId});
  };

  return (<PageLoader loading={loading || !user}>
    <TFACheckContainer
        show={show}
        loading={loading}
        setShow={setShow}
        onSuccess={handleBuyUsdt}
    >
      <IFrameLayout
          processingBefore={showProcessing}
          updatingWhenIFrameOpened={refreshStatus}
          view={!!paymentLink}
          link={paymentLink}
          CongratsComponent={SuccessPage}
          ProcessingComponent={ProcessingPage}
          ErrorComponent={ErrorPage}
          processingFetch={handleUpdateStatus}
          finishedFetch={handleFinishedFetch}
          title={t("buyUsdt")}
          processingRoute={BUY_USDT_KYC_ROUTE}
          continueRoute={WALLET_ROUTE}
          errorRoute={BUY_USDT_KYC_ROUTE}
          arrowRoute={BUY_USDT_KYC_ROUTE}
          cb={handleCallIFrameCallback}
      >
        <Wrapper>
          <Box>
            <PageHeader title={t("buyUsdt")}/>
            {Boolean(!paymentMethod && wertFeatureAvailable) ? <Row gap={'10px'}>
              <Button emoji={"💳"} title="Visa/Master Card" onClick={() => setPaymentMethod(PAYMENT_METHOD.VISA)}/>
              <Button
                  onClick={() => setPaymentMethod(PAYMENT_METHOD.SBP)}
                  title="SBP"
                  LeftIcon={<svg width="19" height="23" viewBox="0 0 19 23" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.5 4.83298L3.18884 9.63911V12.5707L0.503145 17.3674L0.5 4.83298Z"
                          fill="#5B57A2"/>
                    <path
                        d="M10.824 7.89019L13.3435 6.34594L18.4999 6.34113L10.824 11.0435V7.89019Z"
                        fill="#D90751"/>
                    <path d="M10.8098 4.80465L10.824 11.1678L8.12891 9.51179V0L10.8098 4.80465Z"
                          fill="#FAB718"/>
                    <path
                        d="M18.5 6.34113L13.3434 6.34594L10.8098 4.80465L8.12891 0L18.5 6.34113Z"
                        fill="#ED6F26"/>
                    <path
                        d="M10.824 17.394V14.3068L8.12891 12.6823L8.13039 22.2035L10.824 17.394Z"
                        fill="#63B22F"/>
                    <path
                        d="M13.3373 15.8639L3.18866 9.63911L0.5 4.83298L18.4891 15.8576L13.3373 15.8639Z"
                        fill="#1487C9"/>
                    <path
                        d="M8.13062 22.2035L10.8239 17.3941L13.3371 15.8639L18.4889 15.8576L8.13062 22.2035Z"
                        fill="#017F36"/>
                    <path
                        d="M0.503174 17.3674L8.15098 12.6824L5.57982 11.1049L3.18887 12.5707L0.503174 17.3674Z"
                        fill="#984995"/>
                  </svg>}
              />
            </Row> : <>
              {Boolean(loading || !user) ? null : <>
                <Box m="10px 0 8px 0">
                  <Input
                      onlyWholeNumbers
                      value={amount}
                      type="number"
                      placeholder={t("amount")}
                      onChange={setAmount}
                      onFocus={() => setFocusForm(true)}
                      onBlur={() => setFocusForm(false)}
                  />
                </Box>
                <DepositItems
                    onChange={handleChangeDepositAmount}
                    items={depositValues}
                    transform={(el) => `$${el}`}
                    activeItem={activeItem}
                />

                {paymentMethod === PAYMENT_METHOD.SBP && <>
                  <Box m="16px 0 0 0">
                    <LineInfo
                        leftTitle={t("usdExchangeRate")}
                        rightTitle={`~ ${Number(rate).toFixed(2)} RUB`}
                    />
                  </Box>
                  <Row width="100%" justifyContent="space-between">
                    <Typography variant="bold" m="0 0 16px 0">
                      {t("totalAmountDue")}
                    </Typography>
                    <Typography variant="bold" m="0 0 16px 0">
                      {`${Number(finalAmount)?.toFixed(2) || 0} ${VARIANT_PAY_RUB?.currencyName}`}
                    </Typography>
                  </Row>
                  <Typography variant="link">
                    {t("uponSuccessfulPayment")}
                  </Typography>
                </>}
              </>
              }
            </>
            }

          </Box>
          {Boolean(paymentMethod || !wertFeatureAvailable) && <ButtonHorizontal
              bottomMarginForScreen
              variant="small"
              onClick={submitMethod}
              disable={disableButton}
              title={Boolean(loadingPrice || wertLoading) ? (<Spinner/>) : (t("payAmount", {
                amount: finalAmount || 0, currencyName: currency.currencyName,
              }))}
          />}
        </Wrapper>
      </IFrameLayout>
    </TFACheckContainer>
  </PageLoader>);
};
