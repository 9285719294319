import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { abi, useGetWeb3Context } from "../web3";
import useSWR from "swr";
import { useDispatch, useSelector } from "react-redux";
import { initialState, reducer } from "./reducer";
import { setBalanceCoinAction, setBalanceInUsdtAction } from "./actions";
import { CHAINS, CHAINS_COIN } from "../../config";
import { OptimaContexts } from "../OptimazeContexts";
import { useSnackbar } from "../notistack";
import { useGetModule } from "../../hooks/useGetModule";
import {
  AML_ROUTE,
  BUY_USDT_KYC_ROUTE,
  BUY_USDT_ROUTE,
  CARDS_ROUTE,
  MAKER_ROUTE,
  WALLET_ROUTE,
  ZAVOD_BUY_ITEM_ROUTE,
} from "../../routes/routes";
import { useTranslate } from "../i18n";
import {
  getUpdateCardsList,
  removeUpdateCardsList,
} from "../../utils/local-storage-utils";

export const WalletContext = createContext(null);

export const WalletProvider = ({ children }) => {
  const dispatchReact = useDispatch();
  const { t } = useTranslate();
  const walletDispatch = dispatchReact.wallet;
  const cardsDispatch = dispatchReact.cards;
  const btcDispatch = dispatchReact.btc;
  const {
    user,
    currentChain,
    tokens,
    chains,
    tokenBalances,
    isAuth,
    needUpdateTokens,
  } = useSelector(({ wallet }) => wallet);
  const { requirements } = useSelector(({ cards }) => cards);
  const [state, dispatch] = useReducer(reducer, initialState);
  const isWalletModule = useGetModule(WALLET_ROUTE);
  const isMakerModule = useGetModule(MAKER_ROUTE);
  const isCardsModule = useGetModule(CARDS_ROUTE);
  const isBuyUsdModule = useGetModule(BUY_USDT_ROUTE);
  const isKycModule = useGetModule(BUY_USDT_KYC_ROUTE);
  const isAMLModule = useGetModule(AML_ROUTE);
  const isZavodButItem = useGetModule(ZAVOD_BUY_ITEM_ROUTE);
  const [updateBalance, setUpdateBalance] = useState(false);
  const [tokensPriceLoading, setTokensPriceLoading] = useState(false);
  const [chainsLoading, setChainsLoading] = useState(false);
  const { web3Methods } = useGetWeb3Context();
  const [isLoadingBalance, setIsLoadingBalance] = useState(false);
  const [balanceLoaded, setBalanceLoaded] = useState(false);
  const prevBalance = useRef(null);
  const prevCurrentChain = useRef();
  const prevRefreshBalance = useRef(false);
  const createdContract = useRef({});
  const prevChain = useRef(null);
  const [tokensCache, setTokensCache] = useState({});

  const ignoreChains = [CHAINS.BTC];
  const ignoreChain =
    currentChain?.id && ignoreChains.includes(currentChain?.id);

  const userTokens = tokens;
  const { showError } = useSnackbar();

  const chainCoin = useMemo(
    () => CHAINS_COIN[currentChain?.chain],
    [currentChain?.id]
  );

  const handleGetCoinPrice = useCallback(
    (coinName) => () => walletDispatch.getCoinPrice({ coinName }),
    []
  );

  const {
    data,
    isLoading: isLoadingCoinPrice,
    mutate,
  } = useSWR("coinPrice", chainCoin && handleGetCoinPrice(chainCoin));

  const fetchChains = async () => {
    setChainsLoading(true);
    await walletDispatch.getChains();
    setChainsLoading(false);
  };

  const startFetchBalance = useRef(false);

  const fetchBalance = async (chain, userData) => {
    const { getBalance } = await web3Methods(chain);

    return new Promise(async (response, reject) => {
      if (getBalance) {
        let info = user;

        if (userData?.chain && userData?.chain !== chain) return;

        const params = { account: info.wallet };

        return getBalance(params)
          .then((res) => {
            response(res);
          })
          .catch((e) => {
            reject(e);
          });
      }
    });
  };

  const fetchBTCBalance = async () => {
    const { getBalance } = await web3Methods(currentChain.chain);
    if (getBalance) {
      const responseAddresses = await btcDispatch.getAddresses();
      const btcBalance = await getBalance({ balances: responseAddresses });

      if (btcBalance?.convertedBalance > 0) {
        dispatch(setBalanceCoinAction(btcBalance));
      }

      prevRefreshBalance.current = updateBalance;
      prevBalance.current = btcBalance.convertedBalance;
    }
  };

  useEffect(() => {
    if (
        !isWalletModule &&
        !isCardsModule &&
        !isMakerModule &&
        !isAMLModule &&
        !isZavodButItem &&
        !isBuyUsdModule &&
        !isKycModule
    )
      return;

    if (walletDispatch && user?.userId && !chains?.length) {
      fetchChains();
    }
  }, [
    walletDispatch,
    user,
    chains,
    isWalletModule,
    isCardsModule,
    isZavodButItem,
    isAMLModule,
    isMakerModule,
    isBuyUsdModule,
    isKycModule
  ]);

  const loadingBalance = useRef(false);

  useEffect(() => {
    if (currentChain?.chain === CHAINS.BTC) {
      fetchBTCBalance();
      return;
    }

    if (user?.wallet && currentChain?.chain && !loadingBalance.current) {
      setIsLoadingBalance(true);
      setBalanceLoaded(false);
      prevChain.current = currentChain?.chain;
      loadingBalance.current = true;

      fetchBalance(currentChain?.chain, user)
        .then((res) => {
          dispatch(setBalanceCoinAction(res));
          prevBalance.current = res.convertedBalance;
          prevRefreshBalance.current = updateBalance;
          return res;
        })
        .catch((e) => {
          showError(e);
        })
        .finally(() => {
          setBalanceLoaded(true);
          setIsLoadingBalance(false);
          loadingBalance.current = false;
        });
    } else {
      setIsLoadingBalance(false);
      loadingBalance.current = false;
    }
  }, [user?.wallet, web3Methods, currentChain, updateBalance]);

  useEffect(() => {
    if (Object.keys(tokenBalances || {})?.length === userTokens?.length) {
      setTokensPriceLoading(false);
    }
  }, [tokenBalances]);

  useEffect(() => {
    if (currentChain?.id && currentChain?.id === CHAINS.BTC) {
      btcDispatch.getAddresses();
    }
  }, [currentChain, btcDispatch]);

  useEffect(() => {
    if (
      chainCoin &&
      (!prevCurrentChain.current || prevCurrentChain.current !== chainCoin)
    ) {
      mutate();
      prevCurrentChain.current = chainCoin;
    }
  }, [data, chainCoin, mutate]);

  useEffect(() => {
    if (data?.price && data?.symbol.indexOf(chainCoin) > -1) {
      dispatch(
        setBalanceInUsdtAction(
          data?.price * state.balanceCoin?.convertedBalance
        )
      );
    }
  }, [data?.price, state.balanceCoin, chainCoin]);

  useEffect(() => {
    if (!isCardsModule && !isMakerModule && !isAMLModule) return;

    cardsDispatch.getRequirements();
  }, [cardsDispatch, isCardsModule, isMakerModule, isAMLModule]);

  useEffect(() => {
    walletDispatch.setMainTokenIds([
      requirements?.bscUSDT,
      requirements?.trxUSDT,
      requirements?.ethUSDT,
    ]);
  }, [walletDispatch, requirements]);

  const [cardsLoaded, setCardsLoaded] = useState(false);

  useEffect(() => {
    if (!isCardsModule && !isWalletModule) return;

    cardsDispatch.getProducts();
    cardsDispatch.getBins();
  }, [cardsDispatch, isCardsModule]);

  useEffect(() => {
    if (!isCardsModule && !isWalletModule) return;
    cardsDispatch.getCards({ cache: !getUpdateCardsList() }).finally(() => {
      setCardsLoaded(true);
      removeUpdateCardsList();
    });
  }, [cardsDispatch, isCardsModule]);

  const tokensCacheClearTimeoutId = useRef(null);
  const clearTimeoutStarted = useRef(false);

  const handleClearTokensCacheTimeOut = () => {
    return setTimeout(() => {
      setTokensCache({});
      clearTimeoutStarted.current = false;
    }, 45000);
  };

  const handleClearTokensCache = () => {
    setTokensCache({});
  };

  const handleSetTokenInCacheNeedUpdate = (tokenContract, chain) => {
    const tokensHaveFromCache = tokensCache[chain];
    let set = false;

    // LOGIC WHEN NEED SET NEED UPDATE SOME TOKENS

    if (tokensHaveFromCache && tokensHaveFromCache[tokenContract]) {
      tokensHaveFromCache[tokenContract].needUpdate = true;
    }

    // LOGIC ADD TOKEN WHEN ADD NEW TOKEN AND NEED UPDATE HIS BALANCE
    if (tokensHaveFromCache && !tokensHaveFromCache[tokenContract]) {
      tokensHaveFromCache[tokenContract] = { balance: 0, needUpdate: true };
      set = true;
    }

    if (set) {
      setTokensCache((prev) => {
        return {
          ...prev,
          [chain]: tokensHaveFromCache,
        };
      });
    }
  };

  const handleRestartClearTokensCache = () => {
    clearTimeout(tokensCacheClearTimeoutId.current);
    tokensCacheClearTimeoutId.current = handleClearTokensCacheTimeOut();
  };
  const handleStartClearTokensCache = () => {
    clearTimeoutStarted.current = true;
    tokensCacheClearTimeoutId.current = handleClearTokensCacheTimeOut();
  };

  const handleGetTokenBalances = useCallback(
    ({ tokenList, chain, cb = () => {}, userData, noCache }) => {
      const userInfo = { ...user, ...userData };
      if (!clearTimeoutStarted.current) {
        handleStartClearTokensCache();
      }

      let filteredTokens = tokenList?.filter((token) => token.contract);

      const tokensHaveFromCache = tokensCache[chain];

      let tokenHaveFromCacheResponseFormat = null;
      let tokenHaveFromCacheEditFormat = null;
      let needUpdateSomeTokensInCache = false;

      if (tokensHaveFromCache && !noCache) {
        tokenHaveFromCacheEditFormat = Object.entries(tokensHaveFromCache);

        tokenHaveFromCacheResponseFormat = tokenHaveFromCacheEditFormat?.reduce(
          (acc, [contract, value]) => {
            acc[contract] = value.balance;
            return acc;
          },
          {}
        );

        const needUpdateSomeTokens = tokenHaveFromCacheEditFormat
          .filter(([, value]) => value.needUpdate)
          .map(([contract]) => ({ contract }));

        if (needUpdateSomeTokens?.length) {
          needUpdateSomeTokensInCache = true;
          filteredTokens = needUpdateSomeTokens;
        }
      }

      return new Promise(async (res, rej) => {
        if (!filteredTokens?.length) {
          res();
        }

        createdContract.current = {};
        let tokenBalances = {};

        if (tokensHaveFromCache && !needUpdateSomeTokensInCache && !noCache) {
          const rule =
            filteredTokens?.length ===
            Object.keys(tokensHaveFromCache || {}).length;
          if (rule) {
            tokenBalances = tokenHaveFromCacheResponseFormat;

            res(tokenBalances);
            return;
          }

          if (!rule) {
            const ids = tokenList.map((el) => el.contract);

            tokenBalances = Object.entries(tokenHaveFromCacheResponseFormat)
              .filter(([contract, data]) => ids.includes(contract))
              .reduce((acc, [contract, balance]) => {
                acc[contract] = balance;
                return acc;
              }, {});

            const tokensNeedGetBalances = tokenList.filter(
              (token) => !tokenHaveFromCacheResponseFormat[token.contract]
            );

            if (!tokensNeedGetBalances.length) {
              res(tokenBalances);
              return;
            }
          }
        }

        const handleSetTokenBalances = (tokenContract, req) => {
          tokenBalances[tokenContract] = req;

          if (Object.keys(tokenBalances).length === filteredTokens.length) {
            if (needUpdateSomeTokensInCache) {
              let contractIds = Object.entries(tokenBalances).map(
                ([contract]) => contract
              );

              Object.entries(tokenBalances).forEach(([contract, balance]) => {
                tokenHaveFromCacheResponseFormat[contract] = balance;
              });

              const updatedTokensCache = { ...tokensHaveFromCache };

              contractIds.forEach((contract) => {
                updatedTokensCache[contract].needUpdate = false;
                updatedTokensCache[contract].balance = req;
              });

              setTokensCache((prev) => {
                return {
                  ...prev,
                  [chain]: updatedTokensCache,
                };
              });
              res(tokenHaveFromCacheResponseFormat);
              cb();
              setTokensPriceLoading(false);
            } else {
              setTokensPriceLoading(false);
              setTokensCache((prev) => {
                return {
                  ...prev,
                  [chain]: Object.entries(tokenBalances).reduce(
                    (acc, [contract, balance]) => {
                      acc[contract] = { balance, needUpdate: false };

                      return acc;
                    },
                    {}
                  ),
                };
              });

              res(tokenBalances);

              cb();
            }
          }
        };

        try {
          const { getContract, checkValidAddress } = await web3Methods(chain);

          // CHECK IF SOME CONTACT HAVE FROM WRONG NETWORK

          const someContractWrongChain = filteredTokens?.some((el) => {
            return (
              checkValidAddress &&
              el.contract &&
              !checkValidAddress(el.contract)
            );
          });

          if (someContractWrongChain) {
            showError(t("notificationTexts.somethingWrong"));
            setTokensPriceLoading(false);

            if (cb) {
              cb();
            }
            return;
          }

          if (filteredTokens?.length) {
            setTokensPriceLoading(true);
          }

          filteredTokens.forEach(async (token) => {
            if (token?.contract && !createdContract?.current[token?.contract]) {
              createdContract.current[token.contract] = true;

              if (getContract && userInfo?.wallet) {
                const tokenContract = await getContract({
                  abi,
                  address: token.contract,
                });

                if (chain === CHAINS.TRX) {
                  await tokenContract
                    ?.balanceOf(userInfo.wallet)
                    .call()
                    .then((res) => {
                      handleSetTokenBalances(token.contract, res?.toString());
                      return res;
                    })
                    .catch((e) => {})
                    .finally((e) => {
                      if (cb) {
                        cb();
                      }
                    });
                } else {
                  await tokenContract?.methods
                    ?.balanceOf(userInfo.wallet)
                    .call()
                    .then((res) => {
                      handleSetTokenBalances(token.contract, res.toString());
                      return res;
                    })
                    .finally((e) => {
                      if (cb) {
                        cb();
                      }
                    });
                }
              }
            }
          });
        } catch (e) {
          setTokensPriceLoading(false);
          rej(e);

          if (cb) {
            cb();
          }
        }
      });
    },
    [web3Methods, walletDispatch, tokensCache]
  );

  const methods = useMemo(() => {
    return {
      setCoinBalance: (val) => dispatch(setBalanceCoinAction(Number(val))),
      setRefreshBalance: () => {
        prevChain.current = null;
        setUpdateBalance((prev) => !prev);
      },
      setRefreshTokenBalance: () => {
        createdContract.current = {};
      },
    };
  }, [dispatch, updateBalance]);

  const initDefaultChain = useRef(false);

  const prevGetInfoChain = useRef();

  useEffect(() => {
    if (!isAuth) return;

    if (
      walletDispatch &&
      currentChain?.id &&
      prevGetInfoChain.current !== currentChain?.id
    ) {
      walletDispatch.getInfo({
        chain: currentChain.id,
        cache: false,
      });

      prevGetInfoChain.current = currentChain?.id;
    }
  }, [currentChain, isAuth, prevGetInfoChain]);

  useEffect(() => {
    if (!isAuth) return;

    if (
      !currentChain?.chain &&
      chains.length &&
      !initDefaultChain.current &&
      (isWalletModule || isAMLModule || isMakerModule || isZavodButItem)
    ) {
      const BSC = chains.find((chain) => chain.chain === "BSC");

      if (BSC) {
        walletDispatch.setCurrentChain({
          ...BSC,
          id: BSC.chain,
          title: BSC.title,
        });
        initDefaultChain.current = true;
      }
    }
  }, [
    walletDispatch,
    currentChain,
    chains,
    isAuth,
    needUpdateTokens,
    user,
    isWalletModule,
    isAMLModule,
  ]);

  return (
    <WalletContext.Provider
      value={{
        ...state,
        ...methods,
        chainsLoading,
        handleGetTokenBalances,
        handleSetTokenInCacheNeedUpdate,
        handleClearTokensCache,
        fetchBalance,
        isLoadingCoinPrice: !!(isLoadingCoinPrice || !chainCoin || !data),
        isLoadingBalance: isLoadingBalance,
        balanceLoaded,
        cardsLoaded,
        tokensPriceLoading,
      }}
    >
      <OptimaContexts>{children}</OptimaContexts>
    </WalletContext.Provider>
  );
};
