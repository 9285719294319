import React, { memo, useState } from "react";
import styled, { css } from "styled-components";
import { Box, Paper, Row, Typography } from "../../UI";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { useTranslate } from "../../contexts";
import { FROM_PAGE, LOCAL_STORAGE_PASS_KYC_URL_FROM } from "../../config";
import { MAIN_ROUTE, PASS_KYC_ROUTE } from "../../routes/routes";
import { TFACheckContainer } from "../../containers/TFACheckContainer";
import { useSelector } from "react-redux";
import { useGetRouter } from "../../hooks";

const variantButtonSize = {
  small: css`
    height: 44px;
    min-height: 44px;
    padding: 12px 0;
    box-shadow: none;
  `,
  terminate: css`
    height: 44px;
    min-height: 44px;
    padding: 12px 0;
    background-color: ${({ theme }) => theme.colors.background2};
    color: ${({ theme }) => theme.colors.red};
    box-shadow: none;
  `,
};

export const PaperStyled = styled(Paper)`
  background-color: ${({ theme }) => theme.colors.button};
  width: 100%;
  margin-bottom: ${({ bottomMarginForScreen }) =>
    bottomMarginForScreen ? "10px" : "0"};
  opacity: ${(props) => (props.disable ? 0.5 : 1)};
  transition: opacity 0.2s;
  //height: ${({ height }) => height || "100%"};
  min-height: ${({ minHeight }) => minHeight || "77px"};
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.shadows.shadow2};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  ${({ variant }) => (variant ? variantButtonSize[variant] || null : null)};
`;

const TypographyStyled = styled(Typography)`
  color: ${({ theme, activeButton, color }) =>
    color
      ? theme.colors[color]
      : activeButton
      ? theme.colors.white
      : theme.colors.buttonText};
`;

export const Button = memo(
  ({ emoji, title, disable, onClick, activeButton, className, variant, LeftIcon = undefined }) => {
    return (
      <PaperStyled
        className={className}
        variant={variant}
        disable={disable}
        onClick={() => !disable && onClick && onClick()}
      >
          {LeftIcon ? <>{LeftIcon}</> : <Typography>{emoji}</Typography>}
        <TypographyStyled variant="h2" activeButton={!!activeButton}>
          {title}
        </TypographyStyled>
      </PaperStyled>
    );
  }
);

export const ButtonHorizontal = memo(
  ({
    withRef,
    title,
    disable,
    minHeight,
    bottomMarginForScreen,
    onClick,
    color,
    activeButton,
    className,
    variant,
    titleVariant,
    height,
  }) => {
    return (
      <PaperStyled
        height={height}
        minHeight={minHeight}
        ref={withRef}
        variant={variant}
        disable={disable}
        bottomMarginForScreen={bottomMarginForScreen}
        onClick={() => !disable && onClick && onClick()}
        className={className}
      >
        <Row height="100%" alignItems="center" justifyContent="center">
          <TypographyStyled
            activeButton={!!activeButton}
            variant={titleVariant}
            color={color}
          >
            {title}
          </TypographyStyled>
        </Row>
      </PaperStyled>
    );
  }
);

export const handleClickThatBackHere =
  ({ customPathname, pathname, from, onClick }) =>
  () => {
    localStorage.setItem(
      LOCAL_STORAGE_PASS_KYC_URL_FROM,
      customPathname || pathname
    );
    localStorage.setItem(FROM_PAGE, from);
    if (onClick) {
      onClick();
    }
  };

export const PassKycButton = ({ onClick, from, customPathname, title }) => {
  const { t } = useTranslate();
  const { pathname } = useLocation();

  return (
    <NavLink to={"/" + PASS_KYC_ROUTE}>
      <ButtonHorizontal
        bottomMarginForScreen
        variant="small"
        title={title || t("passKycButton")}
        onClick={handleClickThatBackHere({
          customPathname,
          pathname,
          from,
          onClick,
        })}
      />
    </NavLink>
  );
};

const InfoPaper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  min-height: 77px;
  max-height: 77px;
  background-color: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] : theme.colors.background2};
  border-radius: 12px;
`;

export const InfoButton = ({
  title,
  to,
  disableRoute,
  width,
  onClick,
  variant,
}) => {
  const { handleRoute } = useGetRouter();

  const handleRouteFunc = (path) => {
    if (!disableRoute) {
      handleRoute(path);
    }
  };
  return (
    <InfoPaper
      width={width}
      onClick={onClick ? onClick : () => handleRouteFunc(to || MAIN_ROUTE)}
    >
      <Typography ta="center" variant={variant || "h2"} width="90%">
        {title}
      </Typography>
    </InfoPaper>
  );
};

const SecondButtonElement = styled(ButtonHorizontal)`
  background-color: ${({ theme }) => theme.colors.background2};
  padding: 0;
  height: 44px;
  cursor: pointer;
`;
export const SecondButton = (props) => {
  return <SecondButtonElement {...props} />;
};
