import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import MenuImg from "../../assets/img/2.png";
import { CardMenu } from "../../components";
import { useTranslate } from "../../contexts";
import { useGetRouter } from "../../hooks";
import {BUY_USDT_BUY_ROUTE, BUY_USDT_KYC_ROUTE, mainControls, userControls} from "../../routes/routes";
import { Box, Typography } from "../../UI";
import { Image, MenuBlock } from "./styled";
import { NONE } from "../../config";

export const Navigation = React.memo(({ routes, lastButtonFullWidth }) => {
  const { t } = useTranslate();
  const {user} = useSelector(({wallet}) => wallet);
  const integer = routes.length % 2 === 0;

  const routesWithoutLast = integer
    ? routes
    : routes.slice(0, routes.length - 1);
  const lastElement = integer ? null : [...routes].pop();
  console.log(`%c uid ${Boolean(user?.userId)}`, "color: red");
  const getPath = (p) => {
  //
    if (p === BUY_USDT_KYC_ROUTE && user?.userId === 6409134409) {
      return BUY_USDT_BUY_ROUTE;
    }
    return p;
  }
  return (
    <>
      <MenuBlock>
        {routesWithoutLast.map((el, i) => {
          const path = getPath(el.path);
          return (
            // <NavLinkComponents key={el.path} to={`/${el.path}`}>
            <CardMenu
              key={i}
              link={el?.link}
              path={el.pathRoute || path}
              blocked={el.blocked}
              screenName={el?.screenName}
              inBotLink={el.inBotLink}
              isTelegramLink={el.isTelegramLink}
              callback={el.callback}
              lastButtonFullWidth={lastButtonFullWidth}
              actionButton={el.actionButton}
              name={el?.title || t(el.titleKey)}
              icon={el.icon}
            />
            // </NavLinkComponents>
          );
        })}
      </MenuBlock>
      {lastElement && (
        <Box m="8px 0 0 0">
          {/*<NavLinkComponents key={lastElement.path} to={`/${lastElement.path}`}>*/}
          <CardMenu
            path={lastElement?.pathRoute || lastElement.path}
            link={lastElement?.link}
            screenName={lastElement?.screenName}
            lastButtonFullWidth={lastButtonFullWidth}
            actionButton={lastElement.actionButton}
            name={lastElement?.title || t(lastElement.titleKey)}
            icon={lastElement.icon}
          />
          {/*</NavLinkComponents>*/}
        </Box>
      )}
    </>
  );
});

const ProjectsWrapper = styled(Box)`
  //overflow: scroll;
  -webkit-overflow-scrolling: touch;
  -webkit-appearance: none;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1px;
  transform: translateZ(0);
  padding-bottom: 34px;

  //transform: translate3d(0, 0, 0);
`;

const ContentWrapper = styled(Box)`
  //overflow: scroll;
  //padding-bottom: 34px;
  box-sizing: border-box;
  -webkit-appearance: none;
  -webkit-overflow-scrolling: touch;
  backface-visibility: hidden;
  transform: translateZ(0);
  position: relative;
`;

const Wrapper = styled(Box)`
  //height: calc(100vh - 50px);
  //position: relative;
  justify-content: space-between;
`;

export const MenuPage = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const walletDispatch = dispatch.wallet;

  const { isKyc } = useSelector(({ wallet }) => wallet);

  useEffect(() => {
    if (isKyc === NONE) {
      walletDispatch.getKycStatus();
    }
  }, [isKyc, walletDispatch]);

  // const navigationRef = useRef();
  // const contentRef = useRef();
  //
  // const operation = useMemo(() => {
  //   return { minusIndent: 0 };
  // }, []);
  //
  // const { contentHeight } = useGetMainContentHeight(
  //   contentRef,
  //   navigationRef,
  //   operation
  // );

  return (
    // <Wrapper>
    //   <ContentWrapper
    //     ref={contentRef}
    //     height={(contentHeight || window.innerHeight - 50) + "px"}
    //   >
    <ProjectsWrapper>
      <Image src={MenuImg} />
      <Typography m="16px 0 16px 0" variant="h2">{`${t(
        "walletAndCards"
      )?.toUpperCase()}`}</Typography>
      <Navigation routes={userControls} />
      <Typography m="16px 0 16px 0" variant="h2">{`${t(
        "mainMenu"
      )?.toUpperCase()}`}</Typography>
      <Navigation routes={mainControls} />
    </ProjectsWrapper>
    // </ContentWrapper>
    //   <Box ref={navigationRef}></Box>
    // </Wrapper>
  );
};
